import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    creating: false,
    deleting: false,
    stats: {
      pageCount: 1
    }
  },
  data: []
};

export const recipeCollectionReduerSlice = createSlice({
  name: 'recipe_collection',
  initialState,
  reducers: {
    create_recipe_collection: (state) => {
      state.ui.creating = true;
    },
    create_recipe_collection_success: (state, { payload }) => {
      state.ui.creating = false;
      state.data.unshift(payload);
    },
    create_recipe_collection_error: (state) => {
      state.ui.creating = false;
    },
    get_recipe_collection: (state) => {
      state.ui.loading = true;
    },
    get_recipe_collection_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload.collections;
      state.ui.stats = {
        docs: payload.docs,
        limit: payload.limit,
        page: payload.page
      };
    },
    get_recipe_collection_error: (state) => {
      state.ui.loading = false;
    },
    remove_recipe_collection: (state) => {
      state.ui.deleting = true;
    },
    remove_recipe_collection_success: (state, { payload }) => {
      state.ui.deleting = false;
      const response = state.data.filter((data) => data._id !== payload._id);
      state.data = response;
    },
    remove_recipe_collection_error: (state) => {
      state.ui.deleting = false;
    },
    update_recipe_collection: (state) => {
      state.ui.creating = true;
    },
    update_recipe_collection_success: (state, { payload }) => {
      state.ui.creating = false;
      const previousData = state.data;
      const index = previousData.findIndex((data) => data._id === payload._id);
      previousData[index] = payload;
      state.data = previousData;
    },
    update_recipe_collection_error: (state) => {
      state.ui.creating = false;
    }
  }
});

export const {
  create_recipe_collection,
  create_recipe_collection_error,
  create_recipe_collection_success,
  get_recipe_collection,
  get_recipe_collection_success,
  get_recipe_collection_error,
  remove_recipe_collection,
  remove_recipe_collection_error,
  remove_recipe_collection_success,
  update_recipe_collection,
  update_recipe_collection_error,
  update_recipe_collection_success
} = recipeCollectionReduerSlice.actions;

export default recipeCollectionReduerSlice.reducer;
