import * as Yup from 'yup';

export const validateRecipeCollection = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  meal_plan: Yup.string().required('Meal plan is required'),
  servings: Yup.number()
    .typeError('Servings must be a number')
    .positive('Servings must be greater than zero')
    .integer('Servings must be a whole number')
    .required('Servings are required'),
  meal_types: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required('Meal type value is required'),
        label: Yup.string().required('Meal type label is required')
      })
    )
    .min(1, 'At least one meal type is required'),

  recipes: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required('Recipe value is required'),
        label: Yup.string().required('Recipe label is required')
      })
    )
    .min(1, 'At least one recipe is required'),
  price: Yup.object().shape({
    currency: Yup.string().required('Currency is required'),
    value: Yup.number()
      .typeError('Price must be a number')
      .positive('Price must be greater than zero')
      .required('Price is required')
  })
});

export const getRandomImage = (recipes) => {
  if (!recipes.length) return null; // Handle empty array case
  const randomIndex = Math.floor(Math.random() * recipes.length);
  return recipes[randomIndex].image;
};
