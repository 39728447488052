import React, { useState, useEffect } from 'react';
import { Header, Box } from './style';
import {
  getProductGroups,
  getLoadingProductGroups,
  getPageStats,
  getAllGroups
} from './redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  delete_product_group,
  get_all_groups,
  get_product_groups
} from './redux/reducers';
import Table from './Table';
import { FormsModal } from '@app/globalstyles/asgard';
import CreateProductGroup from './components/createProductGroup';
import Modal from '@app/components/modal';
import UpdateGroup from './components/updateGroup';
import EmptyDataUi from '@app/components/emptyData';
import useDebounce from '@app/Hooks/useDebounce.js';
import Pagination from '@app/components/pagination';
import { HiOutlineFolderDownload } from 'react-icons/hi';
import moment from 'moment';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');

const fields = ['Name', 'Description', 'Image', 'Date'];
const json2csvParser = new Parser({ fields });

const ProductGroups = () => {
  const groups = useSelector(getProductGroups);
  const loading = useSelector(getLoadingProductGroups);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [box, setBox] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchString, setSearchString] = useState('');
  const debouncedTerm = useDebounce(searchString, 500);
  const pageStats = useSelector(getPageStats);
  const exportList = useSelector(getAllGroups);

  useEffect(() => {
    dispatch(
      get_product_groups({
        page: currentPage,
        limit: pageSize,
        searchString: debouncedTerm
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedTerm]);

  useEffect(() => {
    dispatch(get_all_groups({ page: 1, limit: pageStats?.docs }));
  }, [dispatch, pageStats]);

  const handleShow = (data) => {
    setHide(!hide);
    setBox(data);
  };

  const handleDelete = (id) => {
    dispatch(delete_product_group(id));
  };

  const getNewGroups = (page, limit) => {
    dispatch(get_product_groups({ page, limit, debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewGroups(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewGroups(1, pageSize);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  function StopBubbling(e) {
    e.stopPropagation();
  }

  const exportData = () => {
    let temp = [];
    exportList.forEach((group) => {
      let obj = {
        Name: group?.name,
        Description: group?.description,
        Image: group?.image,
        Date: moment(group?.created_at).format('LL')
      };
      temp.push(obj);
    });
    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'product-groups.csv');
  };

  return (
    <div>
      <Header>Product Groups</Header>

      <Box>
        <div className="search_bar">
          <input type="text" placeholder="Search" onChange={handleSearch} />
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={exportData}
            className="h-[45px] bg-white border border-[#DDDDDD] flex items-center gap-2 justify-center text-[#333333] font-semibold text-[15px] rounded-[10px] px-10"
          >
            <HiOutlineFolderDownload />
            Export
          </button>

          <button onClick={() => setShow(true)}>Create</button>
        </div>
      </Box>

      {show && (
        <FormsModal show={show} onClick={() => setShow(!show)}>
          <div onClick={StopBubbling}>
            <CreateProductGroup
              StopBubbling={StopBubbling}
              hadleShow={() => setShow(false)}
            />
          </div>
        </FormsModal>
      )}

      {hide && (
        <Modal
          width="400px"
          onClose={() => setHide(false)}
          title="Edit Product Group"
        >
          <UpdateGroup group={box} onClose={handleShow} />
        </Modal>
      )}

      {!loading && groups.length <= 0 ? (
        <EmptyDataUi />
      ) : (
        <Table
          loading={loading}
          groups={groups}
          handleShow={handleShow}
          handleDelete={handleDelete}
        />
      )}
      <Pagination
        itemsCount={pageStats.docs}
        currentPage={currentPage}
        pageSize={pageSize}
        onChangePageSize={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ProductGroups;
