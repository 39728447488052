import moment from 'moment';
import React from 'react';

const IngredientTable = ({ ingredients, handleEdit, handleDelete }) => {
  const head = [
    'Name',
    'Product Group',
    'Product Category',
    'Unit',
    'Date Created',
    ''
  ];
  return (
    <div>
      <table className="w-full border-collapse border border-[#E9E9E9] rounded-md overflow-auto">
        <thead className="bg-[#F9F9FB]">
          <tr>
            {head.map((head, idx) => (
              <th
                key={idx}
                className="text-left py-2 px-4 text-[#4F4F4F] text-[14px] font-medium"
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ingredients.map((ingredient) => (
            <tr
              key={ingredient._id}
              className="border border-[#E9E9E9] hover:bg-[#FDFDFD] cursor-pointer"
            >
              <td className="py-2 px-4 text-left">
                <p className="text-[#4F4F4F] capitalize text-[16px] font-medium">
                  {ingredient.name}
                </p>
                <p className="text-[#5E626A] capitalize text-[14px] font-normal">
                  {ingredient.description || 'N/A'}
                </p>
              </td>
              <td className="py-2 px-4 text-left text-[16px] font-medium text-[#4F4F4F]">
                {ingredient?.product_group?.name}
              </td>
              <td className="py-2 px-4 text-left text-[16px] font-medium text-[#4F4F4F]">
                {ingredient?.product_category?.name || 'N/A'}
              </td>
              <td className="py-2 px-4 text-left text-[16px] font-medium text-[#4F4F4F]">
                {ingredient?.unit?.name || 'N/A'}
              </td>
              <td className="py-2 px-4 text-left text-[16px] font-medium text-[#4F4F4F]">
                {moment(ingredient?.created_at).format('LL')}
              </td>
              <td className="py-2 px-4">
                <div className="flex items-center gap-2 ">
                  <div
                    onClick={() => handleEdit(ingredient)}
                    className="text-[#549A9A] cursor-pointer text-[14px] font-medium"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => handleDelete(ingredient._id)}
                    className="text-[#FF0000] cursor-pointer text-[14px] font-medium"
                  >
                    Delete
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IngredientTable;
