import { createSelector } from '@reduxjs/toolkit';

const recipeCollectionData = (state = {}) => state.recipe_collection;

export const getRecipeCollections = createSelector(
  recipeCollectionData,
  (recipeCollectionData) => recipeCollectionData.data
);

export const getRecipeCollectionPageStats = createSelector(
  recipeCollectionData,
  (recipeCollectionData) => recipeCollectionData.ui.stats
);

export const getRecipeCollectionLoading = createSelector(
  recipeCollectionData,
  (recipeCollectionData) => recipeCollectionData.ui.loading
);

export const getRecipeCollectionCreating = createSelector(
  recipeCollectionData,
  (recipeCollectionData) => recipeCollectionData.ui.creating
);

export const removingRecipeCollection = createSelector(
  recipeCollectionData,
  (recipeCollectionData) => recipeCollectionData.ui.deleting
);
