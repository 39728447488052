import { Input, TextArea } from '@app/pages/meals/components/addIngredients';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { validateRecipeCollection } from '@app/utilities/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getRecipeCollectionCreating } from '../redux/selector';
import {
  create_recipe_collection,
  update_recipe_collection
} from '../redux/reducer';
import { ImSpinner } from 'react-icons/im';

const CreateEditRecipeCollection = ({
  isOpen,
  onClose,
  recipes,
  currencies,
  content,
  isEdit
}) => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(getRecipeCollectionCreating);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (isEdit) {
      setFile(content?.uploaded_doc || null);
      setImage(content?.image_url || null);
    } else {
      setFile(null);
      setImage(null);
    }
  }, [isEdit, content]);

  const fileRef = useRef();
  const imageRef = useRef();

  const handleImage = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        toast.error('Only PDF files are allowed!');
        return;
      }

      setFile(file);
    }
  };

  const meal_types = [
    { value: 'Breakfast', label: 'Breakfast' },
    { value: 'Lunch', label: 'Lunch' },
    { value: 'Dinner', label: 'Dinner' }
  ];

  const recipes_value = recipes?.map((data) => {
    return {
      value: data._id,
      label: data.name,
      image: data.image
    };
  });

  let initialValues = {
    name: content?.name || '',
    description: content?.description || '',
    meal_plan: content?.meal_plan || '',
    servings: content?.servings || '',
    meal_types: content?.meal_types
      ? content?.meal_types?.map((data) => {
          return { value: data, label: data };
        })
      : [],
    recipes: content?.recipes
      ? content?.recipes?.map((data) => {
          return { value: data._id, label: data.name, image: data.image };
        })
      : [],
    price: {
      currency: content?.price?.currency?._id || '',
      value: content?.price?.value || 0
    },
    uploaded_doc: ''
  };

  const handleRefFile = () => {
    fileRef.current?.click();
  };

  const handleImageRef = () => {
    imageRef.current?.click();
  };

  const submitForm = (values) => {
    const meal_types = values.meal_types.map((data) => data.value);
    const recipes = values.recipes.map((data) => data.value);

    if (file === null) {
      toast.error('Uploaded document is required');
      return;
    }

    const data = {
      ...values,
      meal_types: meal_types,
      recipes: recipes,
      uploaded_doc: file,
      image_url: image
    };

    if (isEdit) {
      dispatch(update_recipe_collection({ data, id: content?._id }));
    } else {
      dispatch(create_recipe_collection(data));
    }
  };
  return (
    <Modal
      title={isEdit ? 'Edit Recipe Collection' : 'Create Recipe Collection'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validateRecipeCollection}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={submitForm}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            handleBlur
          }) => (
            <Form className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Name <span className="text-[12px] text-red-500">*</span>
                </label>
                <Input
                  name="name"
                  handleChange={handleChange}
                  value={values.name}
                  handleBlur={handleBlur}
                  placeholder={'Name'}
                  isRequired={true}
                />
                {errors.name && touched.name && (
                  <p className="mt-1 text-sm text-red-500 font-medium">
                    {errors.name}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Description
                </label>
                <TextArea
                  name="description"
                  handleChange={handleChange}
                  value={values.description}
                  placeholder={'Description'}
                  isRequired={false}
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Meal Plan <span className="text-[12px] text-red-500">*</span>
                </label>
                <Input
                  name="meal_plan"
                  handleChange={handleChange}
                  value={values.meal_plan}
                  handleBlur={handleBlur}
                  placeholder={'7 days'}
                  isRequired={true}
                />
                {errors.meal_plan && touched.meal_plan && (
                  <p className="mt-1 text-sm text-red-500 font-medium">
                    {errors.meal_plan}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Servings <span className="text-[12px] text-red-500">*</span>
                </label>
                <Input
                  name="servings"
                  handleChange={handleChange}
                  value={values.servings}
                  placeholder={'1'}
                  isRequired={true}
                  handleBlur={handleBlur}
                  type={'number'}
                />
                {errors.servings && touched.servings && (
                  <p className="mt-1 text-sm text-red-500 font-medium">
                    {errors.servings}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Meal Type <span className="text-[12px] text-red-500">*</span>
                </label>
                <Select
                  isMulti
                  isSearchable
                  options={meal_types}
                  value={values.meal_types}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('meal_types', value);
                  }}
                  className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                  isRequired
                />
                {errors.meal_types && touched.meal_types && (
                  <p className="mt-1 text-sm text-red-500 font-medium">
                    {errors.meal_types}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Recipes <span className="text-[12px] text-red-500">*</span>
                </label>
                <Select
                  isMulti
                  options={recipes_value}
                  value={values.recipes}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('recipes', value);
                  }}
                  className="text-sm font-normal rounded-md w-full outline-none duration-700 ease-in-out focus:border-[#003333] focus:border-opacity-50 focus:shadow-nject-primary focus:ring focus:ring-[#ACD1D1] focus:ring-opacity-30"
                  isRequired
                />
                {errors.recipes && touched.recipes && (
                  <p className="mt-1 text-sm text-red-500 font-medium">
                    {errors.recipes}
                  </p>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-[#14151A] text-[14px] font-medium">
                  Price <span className="text-[12px] text-red-500">*</span>
                </label>
                <div className="border border-[#E0E0E0] border-1 rounded-[5px] h-[45px] flex align-middle">
                  <select
                    value={values.price.currency}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue('price.currency', value);
                    }}
                    className="h-full border-r-[#E0E0E0] border-y-0 border-l-0 rounded-none bg-transparent focus:outline-none text-[#4F4F4F] text-[15px] font-medium"
                  >
                    <option value={''} selected disabled>
                      Currency
                    </option>
                    {currencies.map((data) => (
                      <option
                        className="text-[#4F4F4F] text-[15px] font-medium"
                        key={data._id}
                        value={data._id}
                      >
                        {data.short_code} {data.symbol}
                      </option>
                    ))}
                  </select>
                  <input
                    className="w-full p-4 focus:border-0 focus:outline-none bg-transparent text-sm font-normal"
                    type="number"
                    value={values.price.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue('price.value', value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg"
                  onChange={handleImage}
                  ref={imageRef}
                  className="hidden"
                />
                <label>Choose Image</label>

                {image !== null ? (
                  <div>
                    <img
                      src={
                        typeof image === 'string'
                          ? image
                          : URL.createObjectURL(image)
                      }
                      alt={'shared'}
                      className="w-full h-[300px] object-contain"
                      onClick={handleImageRef}
                    />
                  </div>
                ) : (
                  <div
                    className="cursor-pointer border border-dashed grid place-items-center h-[300px] rounded-md"
                    onClick={handleImageRef}
                  >
                    <div className="text-gray-600">Choose File</div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFile}
                  ref={fileRef}
                  className="hidden"
                />
                <label className="text-[#14151A] text-[14px] font-medium">
                  Choose PDF file <span className="text-[12px] text-red-500">*</span>
                </label>
                {content?.uploaded_doc ? (
                  <div className="flex justify-between items-center mt-2">
                    {file?.name ? (
                      <p className="text-sm text-[#549A9A]">{file?.name}</p>
                    ) : (
                      <a
                        href={file}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm underline text-[#549A9A]"
                      >
                        View Document
                      </a>
                    )}

                    <button
                      onClick={handleRefFile}
                      type="button"
                      className="bg-[#549A9A] text-white rounded-[5px] text-[14px] font-semibold p-2"
                    >
                      Change
                    </button>
                  </div>
                ) : (
                  <div className="inline-block">
                    <div
                      onClick={handleRefFile}
                      className="bg-[#EDFAFA] cursor-pointer border border-1 border-dashed border-[#549A9A] rounded-md p-2 text-[#549A9A] text-sm font-medium"
                    >
                      {file !== null ? file?.name : 'Select File'}
                    </div>
                  </div>
                )}
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-[#549A9A] text-white rounded-[5px] h-[55px] text-[18px] font-semibold mt-5 grid place-items-center"
                >
                  {loading ? (
                    <ImSpinner className="animate-spin" />
                  ) : isEdit ? (
                    'Save'
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateEditRecipeCollection;

const Modal = ({ children, title, isOpen, onClose, onClick }) => {
  if (!isOpen) return null;
  return (
    <div onClick={onClick}>
      <div
        className={`fixed  inset-0  bg-[#14151A80] duration-700 ease-in-out opacity-50 z-[60]  h-screen w-full`}
      />
      <div
        onClick={onClick}
        className={`max-w-[640px] w-full fixed duration-700 ease-in-out top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-md bg-white z-[70] p-6 rounded-[6px]`}
      >
        <div>
          <div className="flex items-center justify-between mb-4">
            <p className="text-[#14151A] font-semibold text-[20px]">{title}</p>
            <div
              onClick={onClose}
              id="close"
              className="w-6 h-6 rounded-full bg-[#0A0F290A] grid place-items-center cursor-pointer"
            >
              {closeIcon}
            </div>
          </div>
          <div className=" max-h-[600px] overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

const closeIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99993 6.17461L9.88743 3.28711L10.7123 4.11194L7.82476 6.99944L10.7123 9.88694L9.88743 10.7118L6.99993 7.82428L4.11243 10.7118L3.2876 9.88694L6.1751 6.99944L3.2876 4.11194L4.11243 3.28711L6.99993 6.17461Z"
      fill="#0F1324"
      fill-opacity="0.6"
    />
  </svg>
);
