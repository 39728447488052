import React, { useEffect, useState } from 'react';
import CreateEditRecipeCollection from './component/create-edit-recipe-collection';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencies } from '../currrencies/redux/selectors';
import { get_currencies } from '../currrencies/redux/reducer';
import { get_recipe_collection, remove_recipe_collection } from './redux/reducer';
import {
  getRecipeCollectionLoading,
  getRecipeCollectionPageStats,
  getRecipeCollections,
  removingRecipeCollection
} from './redux/selector';
import RecipeCollectionCard from './component/recipe-collection-card';
import { getMeals } from '../meals/redux/selector';
import { get_meals } from '../meals/redux/reducer';
import useDebounce from '@app/Hooks/useDebounce';
import { IoSearch } from 'react-icons/io5';
import LoadingDataUi from '@app/components/loading';
import Pagination from '@app/components/pagination';

const RecipeCollection = () => {
  const [show, setShow] = useState(false);
  const recipes = useSelector(getMeals);
  const currencies = useSelector(getCurrencies);
  const dispatch = useDispatch();
  const recipes_collection = useSelector(getRecipeCollections);
  const [content, setContent] = useState({});
  const [deleting, setDeleting] = useState('');
  const removingRecipe = useSelector(removingRecipeCollection);
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const debouncedTerm = useDebounce(search, 500);
  const [isFocused, setIsFocused] = useState(false);
  const loading = useSelector(getRecipeCollectionLoading);
  const pageStats = useSelector(getRecipeCollectionPageStats);

  useEffect(() => {
    dispatch(get_recipe_collection({ page, limit, search: debouncedTerm }));
  }, [dispatch, page, limit, debouncedTerm]);

  useEffect(() => {
    dispatch(get_meals({ page: 1, limit: 200 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_currencies());
  }, [dispatch]);

  const onClose = () => {
    setShow(false);
    setContent({});
    setIsEdit(false);
  };

  const handleView = (doc) => {
    setContent(doc);
    setIsEdit(true);
    setTimeout(() => {
      setShow(true);
    }, 500);
  };

  const handleDelete = (id) => {
    setDeleting(id);
    dispatch(remove_recipe_collection(id));
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const getNewRecipeCollection = (page, limit) => {
    dispatch(get_recipe_collection({ page, limit, search: debouncedTerm }));
  };

  const handlePageChange = (page) => {
    setPage(page);
    getNewRecipeCollection(page, limit);
  };

  const handlePageSizeChange = () => {
    setLimit(parseInt(limit));
    setPage(1);
    getNewRecipeCollection(1, limit);
  };

  return (
    <div>
      <CreateEditRecipeCollection
        isOpen={show}
        onClose={onClose}
        recipes={recipes}
        currencies={currencies}
        content={content}
        isEdit={isEdit}
      />

      <div className="px-5 py-6 border-b-[#DEE5ED] border-b">
        <p className="text-[40px] font-bold">Recipe Collection</p>
        <p className="text-[#313030] text-[14px] font-light">
          Manage recipe collection easily like adding adding a collection, editing
          and deleting
        </p>
      </div>

      <div className="my-6 flex items-center justify-between px-5">
        <div
          className={`flex items-center duration-700 ease-in-out ${
            isFocused
              ? 'border border-[#003333] border-opacity-50 shadow-[#003333] ring ring-[#ACD1D1] ring-opacity-30'
              : 'border border-[#E0E0E0] '
          } rounded-[10px] w-[400px] px-3`}
        >
          <IoSearch size={20} color="#64748B" />
          <input
            type="text"
            placeholder="Search recipe collection"
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-3 bg-transparent focus:border-0 focus:outline-none"
          />
        </div>

        <button
          onClick={() => setShow(true)}
          className="h-[51px] bg-[#549A9A] text-white font-semibold text-[15px] rounded-[5px] w-[200px]"
        >
          Add new
        </button>
      </div>

      {loading ? (
        <LoadingDataUi />
      ) : (
        <div>
          <div className="grid grid-cols-1 lg:grid-cols-4 px-5 gap-4 mt-[50px]">
            {recipes_collection.map((recipe) => (
              <RecipeCollectionCard
                key={recipe._id}
                recipe={recipe}
                handleView={handleView}
                handleDelete={handleDelete}
                deleting={removingRecipe}
                delete_id={deleting}
              />
            ))}
          </div>
        </div>
      )}

      <div className="mt-6">
        <Pagination
          itemsCount={pageStats?.docs || 3}
          pageSize={limit}
          currentPage={page}
          onChangePageSize={handlePageSizeChange}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default RecipeCollection;
