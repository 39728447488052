import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ui: {
    loading: false,
    category_loading: false,
    stats: {
      pageCount: 1
    }
  },
  data: [],
  all_categories: []
};

export const productCategoriesReducerSlice = createSlice({
  name: 'product_categories',
  initialState,
  reducers: {
    get_all_categories_success: (state, { payload }) => {
      state.ui.loading = false;
      state.all_categories = payload.categories;
    },
    get_all_categories_error: (state) => {
      state.ui.loading = false;
    },
    get_all_categories: (state) => {
      state.ui.loading = true;
    },
    get_product_categories_success: (state, { payload }) => {
      state.ui.loading = false;
      state.data = payload.categories;
      state.ui.stats = payload.stats;
    },
    get_product_categories_error: (state) => {
      state.ui.loading = false;
    },
    get_product_categories: (state) => {
      state.ui.loading = true;
    },
    create_product_category_success: (state, { payload }) => {
      state.ui.category_loading = false;
      state.data.unshift(payload);
    },
    create_product_category_error: (state) => {
      state.ui.category_loading = false;
    },
    create_product_category: (state) => {
      state.ui.category_loading = true;
    },
    edit_product_category: (state) => {
      state.ui.category_loading = true;
    },
    edit_product_category_success: (state, { payload }) => {
      state.ui.category_loading = false;
      const prev = state.data;
      const existingCategory = prev.findIndex(
        (category) => category._id === payload._id
      );
      prev[existingCategory] = payload;
      state.data = prev;
    },
    edit_product_category_error: (state) => {
      state.ui.category_loading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  get_product_categories,
  get_product_categories_error,
  get_product_categories_success,
  create_product_category,
  create_product_category_error,
  create_product_category_success,
  edit_product_category,
  edit_product_category_success,
  edit_product_category_error,
  get_all_categories,
  get_all_categories_error,
  get_all_categories_success
} = productCategoriesReducerSlice.actions;

export default productCategoriesReducerSlice.reducer;
