import authReducerSlice from '@auth/redux/reducer';
import { combineReducers } from 'redux';
import currenciesReducerSlice from './pages/currrencies/redux/reducer';
import customerReducerSlice from './pages/customer/redux/reducer';
import customersReducerSlice from './pages/customers/redux/reducer';
import groupReducerSlice from './pages/group/redux/reducer';
import groupsReducerSlice from './pages/groups/redux/reducer';
import dashboardReducerSlice from './pages/home/redux/reducer';
import productCategoriesReducerSlice from './pages/product-categories/redux/reducer';
import productReducerSlice from './pages/product/redux/reducer';
import productsReducerSlice from './pages/products/redux/reducer';
import staffReducerSlice from './pages/partners/redux/reducer';
import paymentReducerSlice from './pages/payments/redux/reducer';
import ordersReducerSlice from './pages/international-orders/redux/reducer';
import paidBasketsReducerSlice from './pages/orders/redux/reducer';
import couponsReducerSlice from './pages/coupons/redux/reducer';
import recipeReducerSlice from './pages/recipes/redux/reducer';
import recipesReducerSlice from './pages/recipe/redux/reducer';
import productGroupsReducerSlice from './pages/product-groups/redux/reducers';
import partnerReducerSlice from './pages/partner/redux/reducer';
import driveReducerSlice from './pages/akaani-drive/redux/reducers';
import deliveryReducerSlice from './pages/deliveryPartner/redux/reducers';
import unitsReducerSlice from './pages/unit/redux/reducers';
import mealsReducerSlice from './pages/meals/redux/reducer';
import mealFactsReducerSlice from './pages/facts/redux/reducer';
import mealCategoriesReducerSlice from './pages/mealCategories/redux/reducer';
import healthConditionReducerSlice from './pages/health/redux/reducer';
import tagsReducerSlice from './pages/meal-tags/redux/reducers';
import recipeCollectionReduerSlice from './pages/recipe-collection/redux/reducer';

const rootReducers = combineReducers({
  auth: authReducerSlice,
  dashboard: dashboardReducerSlice,
  groups: groupsReducerSlice,
  customers: customersReducerSlice,
  international_products: productsReducerSlice,
  currencies: currenciesReducerSlice,
  product_categories: productCategoriesReducerSlice,
  product: productReducerSlice,
  group: groupReducerSlice,
  customer: customerReducerSlice,
  staff: staffReducerSlice,
  payment: paymentReducerSlice,
  orders: ordersReducerSlice,
  basket_orders: paidBasketsReducerSlice,
  coupons: couponsReducerSlice,
  recipe: recipeReducerSlice,
  recipes: recipesReducerSlice,
  product_groups: productGroupsReducerSlice,
  partner: partnerReducerSlice,
  drive: driveReducerSlice,
  delivery: deliveryReducerSlice,
  units: unitsReducerSlice,
  meals: mealsReducerSlice,
  facts: mealFactsReducerSlice,
  meal_categories: mealCategoriesReducerSlice,
  health: healthConditionReducerSlice,
  tags: tagsReducerSlice,
  recipe_collection: recipeCollectionReduerSlice
});
export default rootReducers;
