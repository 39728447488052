import ProfilePage from './profile';
import DashboardPage from './home';
import ProductsPage from './products';
import ProductPage from './product';
import UsersPage from './customers';
import UserPage from './customer';
import PartnersPage from './partners';
import PaymentsPage from './payments';
import InternationalOrdersPage from './international-orders';
import LogoutPage from './logout';
import GroupPage from './group';
import GroupsPage from './groups';
import PaymentPage from './payment';
import Coupons from './coupons';
import OrderPage from './international-orders/order';
import Recipe from './recipes';
import RecipePage from './recipe';
import SettingsPage from './settings';
// import ConverttoMeal from './recipes/components/ConverttoMeal';
import ConvertMealPage from './Meal';
import Categories from './categories';
import ProductGroups from './product-groups';
import ViewProducts from './partner/viewPartner/ViewProducts';
import Order from './order';

import {
  DASHBOARDHOME,
  PROFILE,
  PRODUCTS,
  CUSTOMERS,
  STAFF,
  PAYMENTS,
  LOGOUT,
  PREVIOUS_ORDERS,
  GROUPS,
  PRODUCT,
  GROUP,
  CUSTOMER,
  COUPONS,
  PAYMENT,
  ORDERS,
  MAIN_ORDERS,
  RECIPE,
  RECIPES,
  MEAL,
  SETTINGS,
  CATEGORIES,
  PRODUCT_GROUPS,
  PARTNERS,
  PARTNERS_PAGE,
  PARTNERS_PRODUCT,
  PRODUCTS_PART,
  AKAANI_DRIVE,
  EDIT_PRODUCT,
  ORDER,
  UNITS,
  DELIVERY_PARTNERS,
  MEALS,
  MEALSPAGE,
  CREATEMEALS,
  EDITMEALS,
  FACTS,
  MEAL_CATEGORIES,
  HEALTH,
  MEAL_TAGS,
  RECIPE_COLLECTION,
  INGREDIENTS_LIST
} from './ROUTECONSTS';
import BasketOrders from './orders';
import Partner from './partner';
import ViewPartner from './partner/viewPartner';
import PartnerProducts from './partnerProducts';
import AkaaniDrive from './akaani-drive';
import EditProduct from './partner/components/EditProduct';
import DeliveryPartner from './deliveryPartner';
import Unit from './unit';
import Meals from './meals';
import MealDetails from './mealDetails';
import CreateMeal from './meals/components/CreateMeal';
import Facts from './facts';
import MealCategories from './mealCategories';
import Health from './health';
import MealTags from './meal-tags';
import RecipeCollection from './recipe-collection';
import IngredientsList from './ingriedients-list';

export const dashboardRoutes = [
  {
    path: AKAANI_DRIVE,
    component: AkaaniDrive,
    exact: true,
    protected: true
  },
  {
    path: DELIVERY_PARTNERS,
    component: DeliveryPartner,
    exact: true,
    protected: true
  },
  {
    path: EDIT_PRODUCT,
    component: EditProduct,
    exact: true,
    protected: true
  },
  {
    path: ORDER,
    component: Order,
    exact: true,
    protected: true
  },
  {
    path: PROFILE,
    component: ProfilePage,
    exact: true,
    protected: true
  },
  {
    path: PRODUCTS_PART,
    component: PartnerProducts,
    exact: true,
    protected: true
  },
  {
    path: DASHBOARDHOME,
    component: DashboardPage,
    exact: true,
    protected: true
  },
  {
    path: PREVIOUS_ORDERS,
    component: InternationalOrdersPage,
    exact: true,
    protected: true
  },
  {
    path: RECIPE_COLLECTION,
    component: RecipeCollection,
    exact: true,
    protected: true
  },
  {
    path: INGREDIENTS_LIST,
    component: IngredientsList,
    exact: true,
    protected: true
  },
  {
    path: ORDERS,
    component: OrderPage,
    exact: true,
    protected: true
  },
  {
    path: MEAL_TAGS,
    component: MealTags,
    exact: true,
    protected: true
  },
  {
    path: PRODUCTS,
    component: ProductsPage,
    exact: true,
    protected: true
  },
  {
    path: CATEGORIES,
    component: Categories,
    exact: true,
    protected: true
  },
  {
    path: MEAL_CATEGORIES,
    component: MealCategories,
    exact: true,
    protected: true
  },
  {
    path: HEALTH,
    component: Health,
    exact: true,
    protected: true
  },
  {
    path: PRODUCT_GROUPS,
    component: ProductGroups,
    exact: true,
    protected: true
  },
  {
    path: GROUPS,
    component: GroupsPage,
    exact: true,
    protected: true
  },
  {
    path: MAIN_ORDERS,
    component: BasketOrders,
    exact: true,
    protected: true
  },

  {
    path: CUSTOMERS,
    component: UsersPage,
    exact: true,
    protected: true
  },
  {
    path: RECIPE,
    component: Recipe,
    exact: true,
    protected: true
  },
  {
    path: RECIPES,
    component: RecipePage,
    exact: true,
    protected: true
  },
  {
    path: MEAL,
    component: ConvertMealPage,
    exact: true,
    protected: true
  },
  {
    path: MEALS,
    component: Meals,
    exact: true,
    protected: true
  },
  {
    path: FACTS,
    component: Facts,
    exact: true,
    protected: true
  },
  {
    path: MEALSPAGE,
    component: MealDetails,
    exact: true,
    protected: true
  },
  {
    path: CREATEMEALS,
    component: CreateMeal,
    exact: true,
    protected: true
  },
  {
    path: EDITMEALS,
    component: CreateMeal,
    exact: true,
    protected: true
  },
  {
    path: CUSTOMER,
    component: UserPage,
    exact: true,
    protected: true
  },
  {
    path: COUPONS,
    component: Coupons,
    exact: true,
    protected: true
  },
  {
    path: STAFF,
    component: PartnersPage,
    exact: true,
    protected: true
  },
  {
    path: PAYMENTS,
    component: PaymentsPage,
    exact: true,
    protected: true
  },
  {
    path: PAYMENT,
    component: PaymentPage,
    exact: true,
    protected: true
  },
  {
    path: PARTNERS,
    component: Partner,
    exact: true,
    protected: true
  },
  {
    path: LOGOUT,
    component: LogoutPage,
    exact: true,
    protected: true
  },
  {
    path: PRODUCT,
    component: ProductPage,
    exact: true,
    protected: true
  },
  {
    path: GROUP,
    component: GroupPage,
    exact: true,
    protected: true
  },
  {
    path: PARTNERS_PAGE,
    component: ViewPartner,
    exact: true,
    protected: true
  },
  {
    path: PARTNERS_PRODUCT,
    component: ViewProducts,
    exact: true,
    protected: true
  },
  {
    path: UNITS,
    component: Unit,
    exact: true,
    protected: true
  },
  {
    path: SETTINGS,
    component: SettingsPage,
    exact: true,
    protected: true
  }
];
