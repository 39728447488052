import React, { useState, useEffect } from 'react';
import { CreateMealStyle } from '../meal.styles';
import { getCountries } from 'country-state-picker';
import { useDispatch, useSelector } from 'react-redux';
import {
  create_meal,
  get_ingredients,
  get_single_meal,
  update_meal
} from '../redux/reducer';
import Details from './Details';
import Ingredients from './Ingredients';
import {
  getCreateLoading,
  getIngredients,
  getLoading,
  getMeal,
  getSearching
} from '../redux/selector';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import LoadingDataUi from '@app/components/loading';
import Resizer from 'react-image-file-resizer';
import { getMealCategories } from '@app/pages/mealCategories/redux/selector';
import { get_meal_categories } from '@app/pages/mealCategories/redux/reducer';
import { get_health_conditions } from '@app/pages/health/redux/reducer';
import { getHealthConditions } from '@app/pages/health/redux/selectors';
import Nutrients from './Nutrients';
import HealthCondition from './health-condition';
import { getTags } from '@app/pages/meal-tags/redux/selectors';
import { get_tags } from '@app/pages/meal-tags/redux/reducers';
import useDebounce from '@app/Hooks/useDebounce';
import MacroNutrients from './macronutrients';
import CookingSteps from './cooking-steps';
import { ImSpinner } from 'react-icons/im';
import ProductGroupsSection from './product-groups';
import { getUnits } from '@app/pages/unit/redux/selectors';
import { get_units } from '@app/pages/unit/redux/reducers';

const CreateMeal = () => {
  const [countries, setCountries] = useState([]);
  const [images, setImages] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector(getCreateLoading);
  const loadMeal = useSelector(getLoading);
  const params = useParams();
  const meal = useSelector(getMeal);
  const categories = useSelector(getMealCategories);
  const [mealCatgories, setMealCatgories] = useState([]);
  const healthConditions = useSelector(getHealthConditions);
  const [health, setHealth] = useState([]);
  const tags = useSelector(getTags);
  const [mealTags, setMealTags] = useState([]);
  const ingredients = useSelector(getIngredients);
  const [searchString, setSearchString] = useState(null);
  const debouncedTerm = useDebounce(searchString, 300);
  const searching = useSelector(getSearching);
  const [tabs, setTabs] = useState(['details']);
  const units = useSelector(getUnits);

  useEffect(() => {
    dispatch(get_units({ limit: 50 }));
  }, [dispatch]);

  useEffect(() => {
    if (debouncedTerm !== null) {
      dispatch(get_ingredients({ search: debouncedTerm }));
    }
  }, [debouncedTerm, dispatch]);

  useEffect(() => {
    dispatch(get_tags());
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_meal_categories({ page: 1, limit: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(get_health_conditions({ page: 1, limit: 1000 }));
  }, [dispatch]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    video_url: '',
    description: '',
    types: '',
    countries: ['Nigeria'],
    prep_time: 0,
    ingredients: [{ value: '' }],
    categories: [],
    health_conditions: [],
    fat: 0,
    carbohydrates: 0,
    protein: 0,
    product_groups: [{ value: '', id: '' }],
    ingredients_list: [
      {
        details: { id: '', name: '' },
        description: '',
        quantity: 0,
        unit: { id: '', name: '' }
      }
    ],
    tags: [],
    nutrients: [{ name: '', value: '' }],
    incompatible_medical_conditions: [''],
    instructions: [
      {
        value: ''
      }
    ]
  });

  useEffect(() => {
    if (window.location.pathname.includes('create')) {
      setInitialValues({
        name: '',
        description: '',
        video_url: '',
        types: '',
        countries: [{ value: 'Nigeria', label: 'Nigeria' }],
        prep_time: 0,
        ingredients: [{ value: '' }],
        nutrients: [{ name: '', value: '' }],
        product_groups: [{ value: '', id: '' }],
        categories: [],
        health_conditions: [],
        tags: [],
        incompatible_medical_conditions: [''],
        fat: 0,
        carbohydrates: 0,
        protein: 0,
        ingredients_list: [
          {
            details: { id: '', name: '' },
            description: '',
            quantity: 0,
            unit: { id: '', name: '' }
          }
        ],
        instructions: [
          {
            value: ''
          }
        ]
      });
    } else {
      setImages(meal?.image ? meal?.image : null);
      const ingredients_list = meal?.ingredients_list?.map((data) => {
        return {
          description: data.description,
          quantity: data.quantity,
          details: {
            id: data.ingredient._id,
            name: data.ingredient.name
          },
          unit: {
            id: data?.unit?.id,
            name: data?.unit?.name
          }
        };
      });
      const ingredientsArray = meal?.ingredients?.map((data) => ({
        value: data
      }));
      const instructionsArray = meal?.instructions?.map((data) => ({
        value: data
      }));
      const countries = meal?.countries?.map((data) => ({
        value: data,
        label: data
      }));
      const types = meal?.types?.map((data) => ({ value: data, label: data }));
      const categories = meal?.categories?.map((data) => ({
        value: data._id,
        label: data.name
      }));

      const health = meal?.health_conditions?.map((data) => ({
        value: data._id,
        label: data.name
      }));
      const nutrientsArray = meal?.nutrients?.map((data) => ({
        name: data.name,
        value: data.value
      }));

      const incompatible_medical_conditions =
        meal?.incompatible_medical_conditions?.map((data) => ({
          idx: data
        }));

      const meal_tags = meal?.tags?.map((data) => ({
        label: data.name,
        value: data._id
      }));
      const product_groups = meal?.product_groups?.map((item) => ({
        value: item.name,
        id: item._id
      }));

      setInitialValues({
        name: meal?.name,
        video_url: meal?.video_url,
        ingredients_list:
          ingredients_list?.length > 0
            ? ingredients_list
            : [
                {
                  details: { id: '', name: '' },
                  description: '',
                  quantity: 0,
                  unit: { id: '', name: '' }
                }
              ],
        description: meal?.description,
        countries: countries,
        prep_time: meal?.prep_time,
        ingredients: ingredientsArray,
        instructions: instructionsArray,
        categories: categories,
        fat: meal?.fat,
        protein: meal?.protein,
        product_groups:
          product_groups?.length > 0 ? product_groups : [{ value: '', id: '' }],
        carbohydrates: meal.carbohydrates,
        health_conditions: health,
        tags: meal_tags,
        incompatible_medical_conditions,
        nutrients:
          meal?.nutrients?.length >= 1 ? nutrientsArray : [{ name: '', value: '' }],
        types: types
      });
    }
  }, [meal, params]);

  useEffect(() => {
    if (params.id) {
      dispatch(get_single_meal(params.id));
    }
  }, [dispatch, params]);

  useEffect(() => {
    const countries = getCountries();
    const result = countries.map((data) => {
      return {
        value: data.name,
        label: data.name
      };
    });
    setCountries(result);
  }, []);

  useEffect(() => {
    const response = categories.map((data) => {
      return {
        value: data._id,
        label: data.name
      };
    });
    setMealCatgories(response);
  }, [categories]);

  useEffect(() => {
    const simplifiedTags = tags.flatMap((item) =>
      item.tags.map((tag) => ({
        value: tag._id,
        label: tag.name
      }))
    );
    setMealTags(simplifiedTags);
  }, [tags]);

  useEffect(() => {
    const response = healthConditions.map((data) => {
      return {
        value: data._id,
        label: data.name
      };
    });
    setHealth(response);
  }, [healthConditions]);

  const handlePickImage = (files) => {
    const file = files[0];
    Resizer.imageFileResizer(
      file,
      400,
      500,
      'JPEG',
      900,
      0,
      (uri) => {
        setImages(uri);
      },
      'file'
    );
  };

  const handleIngriedentSearch = (doc) => {
    setSearchString(doc);
  };

  const options = [
    { value: 'Breakfast', label: 'Breakfast' },
    { value: 'Lunch', label: 'Lunch' },
    { value: 'Dinner', label: 'Dinner' }
  ];

  const handleNewSubmit = (doc) => {
    let ingredientsArray = Object.values(doc.ingredients);
    let instructionsArray = Object.values(doc.instructions);
    let stringArray = ingredientsArray.map((data) => data.value);
    let instString = instructionsArray.map((data) => data.value);
    const types = doc.types.map(
      (doc) => doc.value.charAt(0).toUpperCase() + doc.value.slice(1)
    );
    const countries = doc.countries.map((doc) => doc.value);
    const categories = doc.categories.map((doc) => doc.value);
    const health = doc.health_conditions.map((doc) => doc.value);
    const incompatible_medical_conditions = doc.incompatible_medical_conditions.map(
      (doc) => doc.idx
    );
    const tags = doc.tags.map((doc) => doc.value);
    const ingredients_list = doc.ingredients_list.map((data) => {
      const ingredientData = {
        ingredient: data.details.id,
        quantity: Number(data.quantity),
        unit: data.unit.id
      };

      if (data?.description?.trim() !== '') {
        ingredientData.description = data.description;
      }

      return ingredientData;
    });
    const product_groups = doc.product_groups
      .filter((item) => item.value !== '' && item.id !== '')
      .map((item) => item.id);

    let data = {
      ...doc,
      ingredients: stringArray,
      instructions: instString,
      types: types,
      ingredients_list,
      countries,
      categories: categories,
      health_conditions: health,
      product_groups,
      incompatible_medical_conditions: incompatible_medical_conditions,
      images,
      tags
    };
    if (params.id) {
      dispatch(update_meal({ result: data, id: params.id }));
    } else {
      dispatch(create_meal(data));
    }
  };

  const removeImage = () => {
    setImages(null);
  };

  const resetDebouncedTerm = () => {
    setSearchString('');
  };

  const handleTabs = (doc) => {
    setTabs((prev) => {
      if (prev.includes(doc)) {
        return prev.filter((item) => item !== doc);
      } else {
        return [...prev, doc];
      }
    });
  };

  return (
    <CreateMealStyle>
      {loadMeal ? (
        <LoadingDataUi />
      ) : (
        <>
          <div className="header">
            <Link className="link" to="/meals">
              Meals
            </Link>

            <MdChevronLeft color="#605D66" />

            <p>{params.id ? 'Edit Meal' : 'Add Meal'}</p>
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleNewSubmit}
            enableReinitialize={true}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <div className="px-6 mt-10">
                  <div className="w-8/12 flex flex-col gap-10">
                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Details
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('details')}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('details') && (
                        <Details
                          value={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          images={images}
                          countries={countries}
                          handlePickImage={handlePickImage}
                          options={options}
                          params={params}
                          removeImage={removeImage}
                          categories={mealCatgories}
                          health={health}
                          tags={mealTags}
                        />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Health Conditions
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('health_conditions')}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('health_conditions') && (
                        <HealthCondition
                          value={values}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Nutrients
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('nutrients')}
                        >
                          Show
                        </div>
                      </div>

                      {tabs.includes('nutrients') && (
                        <Nutrients value={values} setFieldValue={setFieldValue} />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          MacroNutrients
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('macronutrients')}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('macronutrients') && (
                        <MacroNutrients value={values} handleChange={handleChange} />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Product Group
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('product_groups')}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('product_groups') && (
                        <ProductGroupsSection
                          value={values}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Ingredients List
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => {
                            handleTabs('ingredients_list');
                          }}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('ingredients_list') && (
                        <Ingredients
                          handleIngriedentSearch={handleIngriedentSearch}
                          value={values}
                          setFieldValue={setFieldValue}
                          debouncedTerm={debouncedTerm}
                          ingredients={ingredients}
                          searching={searching}
                          resetDebouncedTerm={resetDebouncedTerm}
                          isEdit={window.location.pathname.includes('edit')}
                          units={units}
                        />
                      )}
                    </div>

                    <div>
                      <div className="flex items-center mb-4 gap-2">
                        <p className="font-semibold text-[#14151A] text-[14px]">
                          Cooking Steps
                        </p>
                        <div className="flex-1 border-b-[1px] border-[#DEE0E3" />
                        <div
                          className="text-[14px] font-medium cursor-pointer text-[#003333]"
                          onClick={() => handleTabs('cooking_steps')}
                        >
                          Show
                        </div>
                      </div>
                      {tabs.includes('cooking_steps') && (
                        <CookingSteps value={values} setFieldValue={setFieldValue} />
                      )}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="grid place-items-center"
                        style={{ width: '100%' }}
                        disabled={loading}
                      >
                        {loading ? (
                          <ImSpinner className="animate-spin" size={20} />
                        ) : (
                          'Save'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CreateMealStyle>
  );
};

export default CreateMeal;
