import React, { useEffect, useState } from 'react';
import AddIngredients from '../meals/components/addIngredients';
import { IoSearch } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExportDataForIngredients,
  getIngredients,
  getIngredientsPageStats,
  getSearching
} from '../meals/redux/selector';
import {
  delete_ingredient,
  get_ingredient_export_list,
  get_ingredients
} from '../meals/redux/reducer';
import IngredientTable from './ingredient-table';
import { HiOutlineFolderDownload } from 'react-icons/hi';
import LoadingDataUi from '@app/components/loading';
import useDebounce from '@app/Hooks/useDebounce';
import moment from 'moment';
import Pagination from '@app/components/pagination';

const { Parser } = require('json2csv');
const fileDownload = require('js-file-download');

const fields = [
  'Name',
  'Description',
  'Product Group',
  'Product Category',
  'Product Url',
  'Unit',
  'Date created'
];
const json2csvParser = new Parser({ fields });

const IngredientsList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const ingredient_list = useSelector(getIngredients);
  const dispatch = useDispatch();
  const [content, setContent] = useState({});
  const loading = useSelector(getSearching);
  const [search, setSearch] = useState('');
  const searchTerm = useDebounce(search, 300);
  const pageStats = useSelector(getIngredientsPageStats);
  const exportList = useSelector(getExportDataForIngredients);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    if (pageStats?.doc) {
      dispatch(get_ingredient_export_list({ page: 1, limit: pageStats?.doc }));
    }
  }, [dispatch, pageStats]);

  useEffect(() => {
    dispatch(get_ingredients({ page: 1, limit: pageSize, search: searchTerm }));
  }, [dispatch, searchTerm, pageSize]);

  const getNewIngredients = (page, limit) => {
    dispatch(get_ingredients({ page, limit, searchString: searchTerm }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getNewIngredients(page, pageSize);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(parseInt(pageSize));
    setCurrentPage(1);
    getNewIngredients(1, pageSize);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleEdit = (doc) => {
    setContent(doc);
    setIsOpen(true);
  };

  const handleDelete = (doc) => {
    dispatch(delete_ingredient(doc));
  };

  const exportData = () => {
    let temp = [];
    exportList.forEach((ingredient) => {
      let obj = {
        Name: ingredient?.name,
        Description: ingredient?.description,
        'Product Group': ingredient?.product_group?.name,
        'Product Category': ingredient?.product_category?.name,
        'Product Url': ingredient?.product_url,
        Unit: ingredient?.unit?.name,
        'Date created': moment(ingredient.created_at).format('LL')
      };
      temp.push(obj);
    });

    const csv = json2csvParser.parse(temp);
    fileDownload(csv, 'Ingredients-list.csv');
  };

  return (
    <div>
      <AddIngredients
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setContent({});
        }}
        edit={Object.keys(content).length > 0}
        list={content}
      />
      <div className="px-5 py-6 border-b-[#DEE5ED] border-b">
        <p className="text-[40px] font-bold">Ingredients List</p>
        <p className="text-[#313030] text-[14px] font-light">
          Manage ingredients list easily like adding adding a list, editing and
          deleting
        </p>
      </div>

      <div className="my-6 flex items-center justify-between px-5">
        <div
          className={`flex items-center duration-700 ease-in-out ${
            isFocused
              ? 'border border-[#003333] border-opacity-50 shadow-[#003333] ring ring-[#ACD1D1] ring-opacity-30'
              : 'border border-[#E0E0E0] '
          } rounded-[10px] w-[400px] px-3`}
        >
          <IoSearch size={20} color="#64748B" />
          <input
            type="text"
            placeholder="Search ingredient list"
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-3 bg-transparent focus:border-0 focus:outline-none"
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={exportData}
            className="h-[45px] bg-white border border-[#DDDDDD] flex items-center gap-2 justify-center text-[#333333] font-semibold text-[15px] rounded-[10px] px-10"
          >
            <HiOutlineFolderDownload />
            Export
          </button>

          <button
            onClick={() => setIsOpen(true)}
            className="h-[45px] bg-[#549A9A] flex items-center gap-2 justify-center text-[#E2FFEE] font-semibold text-[15px] rounded-[10px] px-10"
          >
            <span>{addIcon}</span>
            Create
          </button>
        </div>
      </div>

      <div className="px-5 mt-8 pb-[10px]">
        {loading ? (
          <div>
            <LoadingDataUi />
          </div>
        ) : (
          <div>
            {ingredient_list?.length === 0 ? (
              <div className="h-[50dvh] grid place-items-center">
                <div>
                  <p className="text-center text-[20px] font-bold">
                    No Ingredient has been added
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {' '}
                <IngredientTable
                  ingredients={ingredient_list}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
                <div>
                  <Pagination
                    itemsCount={pageStats.doc}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onChangePageSize={handlePageSizeChange}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IngredientsList;

const addIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4931 5.1278H7.63321V1.24074C7.63321 0.763769 7.2491 0.376953 6.77545 0.376953H5.9177C5.44406 0.376953 5.05994 0.763769 5.05994 1.24074V5.1278H1.20004C0.726399 5.1278 0.342285 5.51462 0.342285 5.99159V6.85538C0.342285 7.33236 0.726399 7.71917 1.20004 7.71917H5.05994V11.6062C5.05994 12.0832 5.44406 12.47 5.9177 12.47H6.77545C7.2491 12.47 7.63321 12.0832 7.63321 11.6062V7.71917H11.4931C11.9668 7.71917 12.3509 7.33236 12.3509 6.85538V5.99159C12.3509 5.51462 11.9668 5.1278 11.4931 5.1278Z"
      fill="#E2FFEE"
    />
  </svg>
);
