// SideMenu Links
export const DASHBOARDHOME = `/dashboard`;
export const PROFILE = `/profile`;
export const PREVIOUS_ORDERS = `/previous-orders`;
export const ORDERS = `/order/:id`;
export const MAIN_ORDERS = `/basket-orders`;
export const PRODUCTS = `/products`;
export const MEALS = `/meals`;
export const CREATEMEALS = `/meals/create/new`;
export const EDITMEALS = `/meals/edit/:id`;
export const MEALSPAGE = `/meals/:id`;
export const FOOD_BASKETS = `/food-baskets`;
export const CUSTOMERS = `/customers`;
export const CUSTOMER = `/customers/:id`;
export const COUPONS = `/coupons`;
export const STAFF = `/staff`;
export const PAYMENTS = `/payments`;
export const PAYMENT = `/payments/:id`;
export const SUBSCRIPTION = `/subscription`;
export const LOGOUT = `/logout`;
export const GROUPS = `/groups`;
export const PRODUCT = `/products/:id`;
export const GROUP = `/groups/:id`;
export const RECIPE = `/recipe`;
export const RECIPES = `/recipe/:id`;
export const MEAL = `/recipe/meal/:id`;
export const SETTINGS = `/settings`;
export const CATEGORIES = `/product-categories`;
export const PRODUCT_GROUPS = `/product-groups`;
export const PARTNERS = `/partners/overview`;
export const PARTNERS_PAGE = `/partners/:id`;
export const PARTNERS_PRODUCT = `/partners/products/:id`;
export const PRODUCTS_PART = `/partners/products`;
export const AKAANI_DRIVE = `/akaani-drive`;
export const EDIT_PRODUCT = `/partners/products/edit/:id`;
export const ORDER = `/basket-orders/:id`;
export const DELIVERY_PARTNERS = '/delivery-partners';
export const DELIVERY_PARTNER = '/delivery-partners/:id';
export const UNITS = '/unit';
export const FACTS = '/facts';
export const MEAL_CATEGORIES = '/meal/categories';
export const HEALTH = `/health-conditions`;
export const MEAL_TAGS = `/meal/tags`;
export const RECIPE_COLLECTION = `/recipe-collection`;
export const INGREDIENTS_LIST = '/ingredrients-list';
// export const MEALS = '/meals'
// Nav Header Links
