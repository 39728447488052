import http from './httpService';

export async function FileUploadToCloud(image) {
  try {
    const formData = new FormData();
    const folder = process.env.REACT_APP_CLOUDINARY_FOLDER || '';
    const name = process.env.REACT_APP_CLOUDINARY_NAME || '';
    formData.append('file', image);
    formData.append('upload_preset', folder);
    const { data } = await http.post(
      `https://api.cloudinary.com/v1_1/${name}/image/upload`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
}

export async function MutipleFileUploadToCloud(images) {
  const image = images.filter((img) => img !== '');
  try {
    const formData = new FormData();
    const folder = process.env.REACT_APP_CLOUDINARY_FOLDER || '';
    const name = process.env.REACT_APP_CLOUDINARY_NAME || '';
    image.map(async (item, index) => {
      if (item && typeof item !== 'string') {
        formData.append('file', item);
        formData.append('upload_preset', folder);
        const { data } = await http.post(
          `https://api.cloudinary.com/v1_1/${name}/image/upload`,
          formData
        );
        image[index] = data.secure_url;
      } else {
        return image;
      }
    });
    return image;
  } catch (error) {
    return error;
  }
}

export async function UploadPdfToCloud(file) {
  try {
    const formData = new FormData();
    const folder = process.env.REACT_APP_CLOUDINARY_FOLDER || '';
    const name = process.env.REACT_APP_CLOUDINARY_NAME || '';
    formData.append('file', file);
    formData.append('upload_preset', folder);
    const { data } = await http.post(
      `https://api.cloudinary.com/v1_1/${name}/raw/upload`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
}
