import dashboardIcon from '@assets/icons/dashboard.svg';
import dashboardActive from '@assets/icons/dashboard-active.svg';

import usersIcon from '@assets/icons/users.svg';
import usersIconActive from '@assets/icons/users-active.svg';

import { LiaStickyNoteSolid } from 'react-icons/lia';
import { FaBowlFood } from 'react-icons/fa6';
import { IoMdSettings } from 'react-icons/io';
import { GiSpoon } from 'react-icons/gi';
import { GiHotMeal } from 'react-icons/gi';
import { IoPricetags } from 'react-icons/io5';

import {
  DASHBOARDHOME,
  CUSTOMERS,
  SETTINGS,
  MEALS,
  PRODUCT_GROUPS,
  FACTS,
  MEAL_TAGS,
  RECIPE_COLLECTION,
  INGREDIENTS_LIST
} from '@app/pages/ROUTECONSTS';

export const menuData = [
  {
    icon: dashboardIcon,
    activeIcon: dashboardActive,
    text: 'Dashboard',
    isActive: false,
    link: DASHBOARDHOME
  },
  {
    icon: usersIcon,
    activeIcon: usersIconActive,
    text: 'Customers',
    isActive: false,
    link: CUSTOMERS
  },
  {
    icon: <GiHotMeal size="25" color="#828282" />,
    activeIcon: <GiHotMeal size="25" color="#fff" />,
    text: 'Meals',
    isActive: false,
    link: MEALS
  },
  {
    icon: <FaBowlFood size="20" color="#828282" />,
    activeIcon: <FaBowlFood size="20" color="#fff" />,
    text: 'Recipes',
    isActive: false,
    link: RECIPE_COLLECTION
  },
  {
    icon: <GiSpoon size="25" color="#828282" />,
    activeIcon: <GiSpoon size="25" color="#fff" />,
    text: 'Ingredients',
    isActive: false,
    link: INGREDIENTS_LIST
  },
  {
    icon: <IoPricetags size="20" color="#828282" />,
    activeIcon: <IoPricetags size="20" color="#fff" />,
    text: 'Meals Tags',
    isActive: false,
    link: MEAL_TAGS
  },
  {
    icon: usersIcon,
    activeIcon: usersIconActive,
    text: 'Product Groups',
    isActive: false,
    link: PRODUCT_GROUPS
  },
  {
    icon: <LiaStickyNoteSolid size="25" color="#828282" />,
    activeIcon: <LiaStickyNoteSolid size="25" color="#fff" />,
    text: 'LU Facts',
    isActive: false,
    link: FACTS
  },
  {
    icon: <IoMdSettings size="25" color="#828282" />,
    activeIcon: <IoMdSettings size="25" color="#fff" />,
    text: 'Settings',
    isActive: false,
    link: SETTINGS
  }
];
