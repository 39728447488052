import { takeEvery, put, call } from '@redux-saga/core/effects';
import {
  create_recipe_collection,
  create_recipe_collection_error,
  create_recipe_collection_success,
  get_recipe_collection,
  get_recipe_collection_success,
  get_recipe_collection_error,
  remove_recipe_collection,
  remove_recipe_collection_error,
  remove_recipe_collection_success,
  update_recipe_collection,
  update_recipe_collection_error,
  update_recipe_collection_success
} from './reducer';
import api from '@service/DataService';
import toast from 'react-hot-toast';
import {
  FileUploadToCloud,
  UploadPdfToCloud
} from '@app/services/fileUploadService';

function* createRecipeCollection({ payload }) {
  try {
    const uploadFile = yield call(UploadPdfToCloud, payload.uploaded_doc);
    const uploadImage = yield call(FileUploadToCloud, payload.image_url);
    const data = {
      ...payload,
      uploaded_doc: uploadFile.secure_url,
      image_url: uploadImage.secure_url
    };
    const request = yield call(api.post, `/recipe_collections`, data);
    const request_response = request.data;
    const response = request_response.data;
    toast.success(request_response.message);
    const closeBtn = document.getElementById('close');
    closeBtn?.click();
    yield put({
      type: create_recipe_collection_success.type,
      payload: response
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put({
      type: create_recipe_collection_error.type
    });
  }
}

function* getRecipeCollection({ payload }) {
  try {
    const { page = 1, limit = 10, search } = payload;
    let request = ``;
    if (search) {
      request = yield call(api.get, `/recipe_collections?name=${search}`);
    } else {
      request = yield call(
        api.get,
        `/recipe_collections?page=${page}&limit=${limit}`
      );
    }
    const request_res = request.data;
    const response = request_res.data;
    yield put({
      type: get_recipe_collection_success.type,
      payload: response
    });
  } catch (error) {
    console.log(error?.message);
    yield put({
      type: get_recipe_collection_error.type
    });
  }
}

function* removeRecipeCollection({ payload }) {
  try {
    const request = yield call(api.delete, `/recipe_collections/${payload}`);
    const request_res = request.data;
    const response = request_res.data;
    toast.success(request_res.message);
    yield put({
      type: remove_recipe_collection_success.type,
      payload: response
    });
  } catch (error) {
    yield put({
      type: remove_recipe_collection_error.type
    });
  }
}

function* updateRecipeCollection({ payload }) {
  try {
    const { data, id } = payload;
    let upload = ``;
    let image_url = ``;
    if (typeof data.uploaded_doc === 'object') {
      const file = yield call(UploadPdfToCloud, data.uploaded_doc);
      upload = file.secure_url;
    } else {
      upload = data.uploaded_doc;
    }

    if (typeof data.image_url === 'object') {
      const file = yield call(FileUploadToCloud, data.image_url);
      image_url = file.secure_url;
    } else {
      image_url = data.image_url;
    }
    let doc = {
      ...data,
      uploaded_doc: upload,
      image_url: image_url
    };
    const request = yield call(api.patch, `/recipe_collections/${id}`, doc);
    const request_res = request.data;
    const response = request_res.data;
    toast.success(request_res.message);
    yield put({
      type: update_recipe_collection_success.type,
      payload: response
    });
    const closeBtn = document.getElementById('close');
    closeBtn?.click();
  } catch (error) {
    yield put({
      type: update_recipe_collection_error.type
    });
  }
}

function* RecipeCollectionSagas() {
  yield takeEvery(create_recipe_collection.type, createRecipeCollection);
  yield takeEvery(get_recipe_collection.type, getRecipeCollection);
  yield takeEvery(remove_recipe_collection.type, removeRecipeCollection);
  yield takeEvery(update_recipe_collection.type, updateRecipeCollection);
}

export default RecipeCollectionSagas;
