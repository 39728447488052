import { getRandomImage } from '@app/utilities/helpers';
import commaNumber from 'comma-number';
import React from 'react';
import { ImSpinner } from 'react-icons/im';

const RecipeCollectionCard = ({
  recipe,
  handleView,
  handleDelete,
  deleting,
  delete_id
}) => {
  return (
    <div className="border border-[#DEE5ED] rounded-md">
      {getRandomImage(recipe.recipes) ? (
        <img
          src={
            recipe?.image_url ? recipe?.image_url : getRandomImage(recipe.recipes)
          }
          alt={recipe?.name}
          className="w-full rounded-t-md h-[252px] object-cover border-0"
        />
      ) : (
        <div className="h-[252px] rounded-t-md grid place-items-center bg-[#F2F2F2] text-[#4F4F4F] border-0">
          No Image available{' '}
        </div>
      )}
      <div className="p-4">
        <p className="font-[600] text-[18px] text-[#313030]">{recipe.name}</p>
        <p className="my-4 text-[#4F4F4F] text-[14px] font-normal">
          {recipe.description.slice(0, 200)}...
        </p>
        <p>
          Price: {recipe.price.currency.symbol} {commaNumber(recipe.price.value)}
        </p>

        <div className="border-t border-[#DEE5ED] mt-2 flex items-center justify-between py-2">
          <button
            onClick={() => handleView(recipe)}
            className="text-sm font-medium cursor-pointer text-[#549A9A]"
          >
            View Collection
          </button>
          <button
            className="text-sm text-red-500"
            disabled={deleting}
            onClick={() => handleDelete(recipe._id)}
          >
            {deleting && delete_id === recipe._id ? (
              <ImSpinner className="animate-spin" />
            ) : (
              'Delete Collection'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecipeCollectionCard;
